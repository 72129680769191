.navbar-light {
  transition: all 0.25s ease-in-out;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.15);

  .navbar-brand {
    color: var(--primary-color);
    font-weight: bold;
    visibility: hidden;
    opacity: 0;
    transition: all 0.25s ease-in-out;
    margin-left: calc(2.5rem - 15px);

    &:hover,
    &:focus,
    &:active {
      color: var(--primary-color);
    }
  }

  .navbar-nav {
    .nav-link {
      color: var(--secondary-color);
      font-weight: bold;
      min-width: 90px;
      text-align: center;
      transition: all 0.15s ease-in-out;

      &:hover,
      &:focus,
      &:active,
      &.active {
        color: var(--primary-color);
      }
    }
  }
}

.scrolled-down {
  .navbar-light {
    .navbar-brand {
      visibility: visible;
      opacity: 1;
    }
  }
}
