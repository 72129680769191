.skill-item-container {
  .skill-item {
    color: var(--primary-color);
    font-size: 1.8rem;
    border: 2px solid var(--secondary-color);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin: 1rem 3rem;
    margin-left: 0;
    transition: all 0.25s ease-in-out;
    outline: none;

    &:hover,
    &:focus {
      transform: scale(1.2);
    }
  }

  @media (max-width: 767px) {
    justify-content: left;

    .skill-item {
      margin: 1rem;
      margin-left: 0;
    }
  }
}

.logo-container {
  width: 100%;
  margin-bottom: 1.5rem;

  > img {
    width: 100%;
    max-width: 100px;

    &.mp-logo {
      background-color: var(--secondary-color);
      padding: 1rem;
    }
  }
}

.work-exp {
  p {
    margin: 0px;
  }
}

.prasad-photo {
  width: 100%;
  max-width: 225px;
  border-radius: 50%;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);

  @media (max-width: 767px) {
    max-width: 180px;
    margin: 2rem 0;
  }
}

#works {
  .card-deck {
    &:hover {
      > .card {
        filter: blur(2px) grayscale(1);
        transform: scale(0.95);
      }
    }

    .card {
      transition: all 0.15s ease-in-out;
      text-decoration: none;
      color: inherit !important;

      > .card-body {
        font-size: 1rem;
      }

      &:hover,
      &:focus,
      &:active {
        transform: scale(1.025);
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        filter: blur(0px) grayscale(0);
      }
    }
  }
}

.card-deck {
  > .card {
    margin-bottom: 1rem;
    min-width: 200px;
  }
}
