.banner {
  align-items: center;
  padding: 6rem 0;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
  background-color: rgba(63, 81, 181, 0.08);
  background-image: url("../../assets/img/_uhdocean247.jpg");
  background-size: cover;
  background-position: center;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .banner-text {
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 2.5rem;
    text-align: center;
    transition: all 0.25s ease-in-out;

    > span {
      display: block;
      color: #ffffff;

      &:nth-child(1) {
        font-size: 3rem;
      }
      &:nth-child(2) {
        font-size: 2rem;
      }
    }

    @media (max-width: 767px) {
      padding: 0 1rem;

      > span {
        &:nth-child(1) {
          font-size: 2.5rem;
        }
        &:nth-child(2) {
          font-size: 1.5rem;
        }
      }
    }
  }

  .banner-img {
    text-align: center;
    padding: 3rem;

    > img {
      width: 100%;
      max-width: 450px;
    }
  }

  @media (max-width: 767px) {
    background-position: left bottom;
  }
}

.download-btn {
  background-color: var(--secondary-color);
  color: #ffffff;
  transition: all 0.25s ease-in-out;
  margin-top: 5rem;
  text-transform: none;

  &:hover,
  &:focus {
    color: #ffffff;
    transform: translateY(-5px);
  }
}
