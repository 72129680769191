@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:200,400,700&display=swap);
:root{--primary-color: #f50057;--secondary-color: #1a237e}html{scroll-behavior:smooth}body{margin:0;font-family:"Roboto Slab", serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}body>*{font-family:"Roboto Slab", serif}code{font-family:source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace}main{margin-top:56px}.btn{border-radius:0 !important}.site-section{padding:2.5rem;margin-left:-15px;margin-right:-15px}.site-section .section-title{color:#1a237e;color:var(--secondary-color);font-weight:bold}.site-section .section-title::after{content:"_";color:#f50057;color:var(--primary-color)}.site-section .section-content{font-size:1.2rem}.strong{font-weight:bold}.gray-bg{background-color:#eeeeee}

.navbar-light{-webkit-transition:all 0.25s ease-in-out;transition:all 0.25s ease-in-out;box-shadow:0px 0px 5px rgba(0,0,0,0.15)}.navbar-light .navbar-brand{color:var(--primary-color);font-weight:bold;visibility:hidden;opacity:0;-webkit-transition:all 0.25s ease-in-out;transition:all 0.25s ease-in-out;margin-left:calc(2.5rem - 15px)}.navbar-light .navbar-brand:hover,.navbar-light .navbar-brand:focus,.navbar-light .navbar-brand:active{color:var(--primary-color)}.navbar-light .navbar-nav .nav-link{color:var(--secondary-color);font-weight:bold;min-width:90px;text-align:center;-webkit-transition:all 0.15s ease-in-out;transition:all 0.15s ease-in-out}.navbar-light .navbar-nav .nav-link:hover,.navbar-light .navbar-nav .nav-link:focus,.navbar-light .navbar-nav .nav-link:active,.navbar-light .navbar-nav .nav-link.active{color:var(--primary-color)}.scrolled-down .navbar-light .navbar-brand{visibility:visible;opacity:1}

.banner{align-items:center;padding:6rem 0;box-shadow:0px 0px 5px rgba(0,0,0,0.25);background-color:rgba(63,81,181,0.08);background-image:url(/static/media/_uhdocean247.bb3df325.jpg);background-size:cover;background-position:center;position:relative}.banner::before{content:"";position:absolute;width:100%;height:100%;background-color:rgba(0,0,0,0.4)}.banner .banner-text{text-transform:uppercase;font-weight:bold;padding:0 2.5rem;text-align:center;-webkit-transition:all 0.25s ease-in-out;transition:all 0.25s ease-in-out}.banner .banner-text>span{display:block;color:#ffffff}.banner .banner-text>span:nth-child(1){font-size:3rem}.banner .banner-text>span:nth-child(2){font-size:2rem}@media (max-width: 767px){.banner .banner-text{padding:0 1rem}.banner .banner-text>span:nth-child(1){font-size:2.5rem}.banner .banner-text>span:nth-child(2){font-size:1.5rem}}.banner .banner-img{text-align:center;padding:3rem}.banner .banner-img>img{width:100%;max-width:450px}@media (max-width: 767px){.banner{background-position:left bottom}}.download-btn{background-color:var(--secondary-color);color:#ffffff;-webkit-transition:all 0.25s ease-in-out;transition:all 0.25s ease-in-out;margin-top:5rem;text-transform:none}.download-btn:hover,.download-btn:focus{color:#ffffff;-webkit-transform:translateY(-5px);transform:translateY(-5px)}

.skill-item-container .skill-item{color:var(--primary-color);font-size:1.8rem;border:2px solid var(--secondary-color);width:50px;height:50px;display:flex;align-items:center;justify-content:center;border-radius:50%;margin:1rem 3rem;margin-left:0;-webkit-transition:all 0.25s ease-in-out;transition:all 0.25s ease-in-out;outline:none}.skill-item-container .skill-item:hover,.skill-item-container .skill-item:focus{-webkit-transform:scale(1.2);transform:scale(1.2)}@media (max-width: 767px){.skill-item-container{justify-content:left}.skill-item-container .skill-item{margin:1rem;margin-left:0}}.logo-container{width:100%;margin-bottom:1.5rem}.logo-container>img{width:100%;max-width:100px}.logo-container>img.mp-logo{background-color:var(--secondary-color);padding:1rem}.work-exp p{margin:0px}.prasad-photo{width:100%;max-width:225px;border-radius:50%;box-shadow:0px 3px 5px rgba(0,0,0,0.5)}@media (max-width: 767px){.prasad-photo{max-width:180px;margin:2rem 0}}#works .card-deck:hover>.card{-webkit-filter:blur(2px) grayscale(1);filter:blur(2px) grayscale(1);-webkit-transform:scale(0.95);transform:scale(0.95)}#works .card-deck .card{-webkit-transition:all 0.15s ease-in-out;transition:all 0.15s ease-in-out;text-decoration:none;color:inherit !important}#works .card-deck .card>.card-body{font-size:1rem}#works .card-deck .card:hover,#works .card-deck .card:focus,#works .card-deck .card:active{-webkit-transform:scale(1.025);transform:scale(1.025);box-shadow:0px 2px 5px rgba(0,0,0,0.1);-webkit-filter:blur(0px) grayscale(0);filter:blur(0px) grayscale(0)}.card-deck>.card{margin-bottom:1rem;min-width:200px}

.contact-item{align-items:center}.contact-item .contact-icon{font-size:2rem;width:50px}.contact-item>.span{display:block}

.scrolled-down .scroll-to-top{visibility:visible;opacity:1}.scroll-to-top{visibility:hidden;opacity:0;position:fixed;right:15px;bottom:15px;width:40px;height:40px;text-align:center;border-radius:50%;background-color:#fff;border:2px solid #616161;padding:5px;mix-blend-mode:unset !important;-webkit-transition:all 0.15s ease-in-out;transition:all 0.15s ease-in-out;cursor:pointer;z-index:999997}.scroll-to-top::before{content:"";position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -30%) rotate(45deg);transform:translate(-50%, -30%) rotate(45deg);display:block;width:14px;height:14px;border:2.8px solid #616161;border-right:transparent !important;border-bottom:transparent !important;box-sizing:border-box}.scroll-to-top:hover,.scroll-to-top:focus{-webkit-transform:scale(1.1);transform:scale(1.1)}

