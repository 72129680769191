@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:200,400,700&display=swap");

:root {
  --primary-color: #f50057;
  --secondary-color: #1a237e;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto Slab", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  > * {
    font-family: "Roboto Slab", serif;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

main {
  margin-top: 56px;
}

.btn {
  border-radius: 0 !important;
}

.site-section {
  padding: 2.5rem;
  margin-left: -15px;
  margin-right: -15px;

  .section-title {
    color: var(--secondary-color);
    font-weight: bold;

    &::after {
      content: "_";
      color: var(--primary-color);
    }
  }

  .section-content {
    font-size: 1.2rem;
  }

  // &:not(:last-child) {
  //   padding-bottom: 0;
  // }
}

.strong {
  font-weight: bold;
}

.gray-bg {
  background-color: #eeeeee;
}
