.scrolled-down {
  .scroll-to-top {
    visibility: visible;
    opacity: 1;
  }
}

.scroll-to-top {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  right: 15px;
  bottom: 15px;
  width: 40px;
  height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: #fff;
  border: 2px solid #616161;
  padding: 5px;
  mix-blend-mode: unset !important;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  z-index: 999997;

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -30%) rotate(45deg);
    display: block;
    width: 14px;
    height: 14px;
    border: 2.8px solid #616161;
    border-right: transparent !important;
    border-bottom: transparent !important;
    box-sizing: border-box;
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
  }
}
