.contact-item {
  align-items: center;

  .contact-icon {
    font-size: 2rem;
    width: 50px;
  }

  > .span {
    display: block;
  }
}
